import React from "react"

import { BodyText, Image } from "./FCSimple_Components"

const FlexibleContentSimple = ({ ...props }) => {
  const { typeHandle } = props

  const contentMap = {
    bodyText: BodyText,
    image: Image,
  }

  const Component = contentMap[typeHandle]

  if (!typeHandle || !Component) {
    return null
  }

  return <Component {...props} />
}

export default FlexibleContentSimple
