import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Image = ({ image }) => {
  if (!image || !image[0] || !image[0].localFile) {
    return null
  }

  return (
    <div className="col-start-1 col-span-12 lg:col-span-8">
      <figure className="">
        <div className="aspect-w-3 aspect-h-2">
          <GatsbyImage
            className="w-full h-full object-cover rounded-br-3xl"
            image={getImage(image[0].localFile)}
            alt={image[0].title}
            style={{ position: "absolute" }} // To override Gatsby Image for aspect ratio
          />
        </div>
        <figcaption className="text-md italic text-black text-opacity-75 mt-3">
          {image[0].title}
        </figcaption>
      </figure>
    </div>
  )
}

export default Image
