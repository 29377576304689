import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Hero from "../../components/Hero/Hero"
import FlexibleContentSimple from "../../components/FlexibleContentSimple/FlexibleContentSimple"

import { formatDateTime } from "../../utils/date-time"

const Article = ({ data }) => {
  const {
    metaTitle,
    metaDescription,
    heading,
    postDate,
    flexibleContentSimple,
    image,
  } = data.article

  return (
    <Layout>
      <Seo title={metaTitle || heading} description={metaDescription} />
      <Hero
        heading={heading}
        text={`Posted ${formatDateTime(postDate)}`}
        image={image[0]}
      />
      <section className="py-28 md:py-56">
        <div className="container">
          <div className="grid grid-cols-12 space-y-12">
            {flexibleContentSimple &&
              flexibleContentSimple.map(item => (
                <FlexibleContentSimple key={item.id} {...item} />
              ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Article

export const articleQuery = graphql`
  query ArticleQuery($uri: String) {
    article: craftArticlesDefaultEntry(uri: { eq: $uri }) {
      metaTitle
      metaDescription
      heading: title
      postDate
      image {
        ... on Craft_images_Asset {
          ...imageFragment
        }
      }
      flexibleContentSimple {
        ...flexibleContentSimpleFragment
      }
    }
  }
`
