import React from "react"

const BodyText = ({ text }) => {
  if (!text) {
    return null
  }

  return (
    <div className="col-start-1 col-span-12 lg:col-span-7">
      <div className="rte" dangerouslySetInnerHTML={{ __html: text }}></div>
    </div>
  )
}

export default BodyText
